

@font-face {
  font-family: 'galano_grotesque_alt';
  src: url('./fonts/galano_grotesque_alt_bold.woff2') format('woff2'),
  url('./fonts/galano_grotesque_alt_bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;

}
@font-face {
  font-family: 'galano_grotesque_alt';
  src: url('./fonts/galano_grotesque_alt_italic.woff2') format('woff2'),
  url('./fonts/galano_grotesque_alt_italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;

}
@font-face {
  font-family: 'galano_grotesque_alt';
  src: url('./fonts/galano_grotesque_alt_light.woff2') format('woff2'),
  url('./fonts/galano_grotesque_alt_light.woff') format('woff');
  font-weight: light;
  font-style: normal;

}
@font-face {
  font-family: 'galano_grotesque_alt';
  src: url('./fonts/galano_grotesque_alt.woff2') format('woff2'),
  url('./fonts/galano_grotesque_alt.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body {
  margin: 0;
  padding: 0;
  font-family: "galano_grotesque_alt", "Helvetica Neue", "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
