.Login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .card-container {
    min-width: 400px;
    min-height: 320px;
    width: 20vw;
    height: 20vh;

    .am-card {
      height: 100%;
      padding: 0;

      .am-card-header {
        padding: 16px;
        font-weight: bold;
        background: var(--tertiary-color);
        border-top-left-radius: 8px;
        border-top-right-radius: 4px;
        border: none;
        .am-card-header-content {
          color: var(--primary-color);
          svg {
            margin-right: 24px;
          }
        }
      }
      .am-card-body {
        display: flex;
        align-items: center;
        justify-content: center;

        > div {
          width: 80%;

          img {
            max-width: 80%;
            height: auto;
          }

          .am-button {
            background: var(--tertiary-color);
            color: var(--primary-color);
            cursor: pointer;

            &:hover, &:active {
              color: var(--secondary-color)
            }
          }
        }
      }
    }
  }
}
