.Questionaries {

  .categories, .buttons{
    margin-left: 50px;
    margin-right: 50px;

    .am-button {
      display: inline-block;
      max-width: 250px;
      width: 100%;
      float: left;
      margin: 8px 20px;
    }
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

}
.ant-modal-header{
  background: #25B4B0;
  .ant-modal-title{
    color: #ffffff;
  }
}

.am-button-250 {
  min-width: 250px;
}
.ant-modal-body{

  input{
    width: 60%;
    display: block;
    margin-left: 20%;
  }

  .ant-select{
    margin-top: 25px;
  }
}
