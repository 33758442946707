.Clients {

  height: 100%;
  .Card {
    height: calc(100% - 20px); //2 * margin card
    overflow:hidden;

    --header-height: calc(43px - 24px); //24px padding
    .card-header {
      height: var(--header-height);
    }

    .card-body {
      height: calc(100% - var(--header-height) - 24px);
      overflow-y: scroll;

    }
  }

  .button-col:not(th) {
    text-align: center !important;
  }

}


  .rc-table {
    .rc-table-content {
      margin: 8px 16px;
      table {
        border-collapse: collapse;
        width: 100%;
        text-align: left;

        thead > tr > th {
          background-color: var(--tertiary-color);
          transition: background 0.6s ease;
          text-align: left;
          color: var(--primary-color);
          font-weight: 500;
          padding: 24px 16px;
          cursor: pointer;
          &:hover {
            background: var(--primary-color);
            color: var(--tertiary-color);
          }
        }
        tbody > tr {
          td {
            background: #f9f9f9;
            text-align: left;
            font-weight: 500;
            padding: 16px 16px;
          }
          &:nth-child(2n) td{
            background: #f3f3f3;
          }
        }
      }
    }
  }
