.Card {
  --border: 1px solid rgba(192,192,192,0.25);
  margin: 20px;
  border: var(--border);
  box-sizing: border-box;
  background-color: #fdfdfd;

  .card-header {
    position: relative;
    padding: 12px 24px;
    border-bottom: var(--border);
    h2 {
      font-size: 16px;
      margin: 0;
    }
    .card-header-button-container {
      position: absolute;
      right: 6px;
      top: 6px;
    }
  }

  .card-body {
    min-height: 60px;
    padding: 12px 24px;
  }

  .table-card-header {
    height: 19px;
  }

  .table-card-body {
    height: calc(100% - 43px);
    overflow-y: scroll;

    .table {
      height: calc(100% - 59px);
      overflow:hidden;
    }
  }

}
