.QuestionaryQuestions {

  height: 100%;
  .Card {
    height: calc(100% - 20px); //2 * margin card
    overflow:hidden;

    --header-height: calc(43px - 24px); //24px padding
    .card-header {
      height: var(--header-height);
    }

    .card-body {
      height: calc(100% - var(--header-height) - 24px);
      overflow-y: scroll;

    }
  }


  .textStyling{
    width: 100%;
    margin-bottom: 20px;
    line-height: 34px;

    .svg-inline--fa {
      margin-right: 21px;
      vertical-align: text-bottom;
      font-size: 1.5em;
      &:first-child {
        font-size: 1.6em;
      }
    }

    input{
      width: calc(100% - 176px);
      margin-left: 1%;
      line-height: 22px;
      padding: 4px 12px;
      border: 1px solid #9A9CA5;
    }
    @media screen and (max-width: 767px) {
      input {
        width: calc(100% - 24px);
        display: block;
        margin-left: 0;
      }
    }

    .questionNumber{
      display: inline-block;
      font-size: 24px;
      vertical-align: sub;
      width: 42px;
      line-height: 32px;
    }
  }

  .am-button {
    margin-right: 8px;
    .am-button-250 {
      min-width: 250px;
    }
  }
}

.am-modal-body {
  input {
    width: calc(100% - 96px);
    margin-left: 1%;
    line-height: 22px;
    padding: 4px 12px;
    border: 1px solid #9A9CA5;
  }
}
