.QuestionaryWelcome {

  .am-button {
    margin: 20px 5%;
  }

  .am-button-primary {
    float:right;
  }
}
