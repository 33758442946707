.QuestionaryResult {
  height: 100%;

  .button-col:not(th) {
    text-align: center !important;
  }

  .table {
    height: calc(100% - 20px); //2 * margin card
    overflow:hidden;
  }

  --header-height: calc(43px - 24px); //24px padding
  .table-card-header {
    height: var(--header-height);
  }

  .table-card-body {
    height: calc(100% - var(--header-height) - 24px);
    overflow-y: scroll;
  }

  .question-row {
    border: 1px solid #aaa;
    margin: 0 16px 24px 16px;

    .header {
      min-height: 60px;
      box-sizing: border-box;

      > div {
        padding: 8px;
        float:left;
        box-sizing: border-box;
      }
      .name {
        width: calc(100% - 120px);
        font-size: 16px;
      }

      .type {
        width: 40px;
        font-size: 24px;
      }

      .answers {
        width: 80px;
        font-size: 24px;
      }
      &:after {
        clear:both;
        display: block;
        content: "";
      }
    }
    .body {
      background: #f0f0f0;
      padding: 8px;


      .answer-row {
        min-height: 40px;
        box-sizing: border-box;

        > div {
          padding: 0 8px 8px 8px;
          box-sizing: border-box;
        }

        .name {
          font-weight: bold;
        }
        .rating {
          margin: 0;
          width: 140px;
          .rate-star {
            float: left;
            color: var(--secondary-color);
            font-size: 20px;
          }
          &:after {
            content: "";
            display:block;
            clear: both;
          }

        &:after {
          clear:both;
          display: block;
          content: "";
        }

      }
      }

    }
  }

}
