.QuestionaryContactperson {
  h1{
    margin: 0;
    padding-left: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .stylingCards{
    margin: 0 20px;
    width: 100%;
    display: block;
  }

  .compositioning{
    width: calc(33% - 40px);
    display: inline-block;
  }

  a.ant-btn{
    background-color: #EE7E22 !important;
    color: #ffffff !important;
    width: 15%;
    margin-left: 80%;
    margin-top: 50px;
  }

  .contactButton{
    width: 100%;
  }
  .Card.contact,
  .Card.contact .card-header{
    border-color: var(--tertiary-color);
  }
  .Card.contact.selected,
  .Card.contact.selected .card-header{
    border-color: var(--secondary-color);
  }

}

.ant-modal-body{
  text-align: left;
}
