.ContentEditor {
  margin: 16px 48px;
  border: var(--border);
  position:relative;
  padding: 32px 8px 8px 8px;
  &:before {
    position: absolute;
    content: "Bewerk de content";
    left: 8px;
    top: 4px;
  }
}
