.StatusMessage {
  line-height: 24px;
  padding: 8px 24px;

  .dismiss-button {
    padding: 0 10px;
    line-height: 24px;
    height: 24px;
    vertical-align: middle;
    float: right;
  }
}
